// Migrated to script setup
<template lang="pug">
b-modal(
  ref="translationModal"
  v-model="show"
  :hide-header="true"
  :no-close-on-backdrop="true"
  :no-close-on-esc="true"
  body-class="no-padding"
  centered
  sticky-footer
  modal-class="translation-modal"
  :size="isHtml ? 'sxl' : 'lg'"
  @hide="onHide"
)
  template(#default)
    ModalCloseButton(
      align="border"
      shadow
      @click="onHide"
    )
    Loading(v-if="loading")
    Error(v-else-if="failed")
    .cms-content.pb-4.mb-2(v-else)
      .row
        .col-12.col-xl-12(:class="[hasHtml ? 'col-xxl-4' : 'col-xxl-6 col-sm-6']")
          h4 {{ $t('translations.originalText') }}
        .d-none.flex-column.d-sm-flex.col-xl-6.col-xxl-4(v-if="hasHtml")
          h4 {{ $t('translations.preview') }}
        .col-12(:class="[hasHtml ? 'col-xxl-4 col-xl-6' : 'col-xxl-6 col-xl-12']")
          h4 {{ $t('translations.translation') }}

      .row.mb-5(
        v-for="translate in translations"
        :key="translate?.translationKey"
      )
        .col-12(:class="[isHtml(translate) ? 'col-xxl-4' : 'col-xxl-6 col-xl-6']")
          div(v-html="translate?.originalText")
        .d-none.flex-column.d-sm-flex.col-xl-6.col-xxl-4(v-if="isHtml(translate)")
          div(v-html="translate?.content")
        .col-12(:class="[isHtml(translate) ? 'col-xxl-4 col-xl-12' : 'col-xxl-6 col-xl-6']")
          .form-group.h-100
            textarea.form-control.mh-100(
              id="content"
              v-model="translate.content"
              name="content"
            )
          code.d-block.text-right(style="font-size:.6rem") {{ translate?.translationKey }}
          .badge.badge-md.light-blue-bg.text-white(v-if="translate?.machineTranslated") Machine Translated
  template(#modal-footer)
    .row.w-100.align-items-center
      .col-6.justify-content-start
        .btn-group.btn-group-md
          button.btn.text-black(@click="onHide") {{ $t('formCloseButton') }}
          button.btn.text-black(
            :disabled="!showUndo"
            @click="regret"
          ) {{ $t('formRegret') }}
      .col-6.justify-content-end.text-right
        .badge.badge-md.bright-red-bg.text-white(v-if="!htmlIsValid") Invalid HTML
        .btn-group.btn-group-lg.ml-3
          SubmitButton.btn.green-bg.text-white(
            :disabled="!htmlIsValid"
            :loading="requestLoading"
            @click="onSave"
          ) {{ $t('bookSaveChanges') }}
</template>

<script setup>
import events from '@/constants/events'
import Loading from '@/components/alerts/Loading'
import Error from '@/components/common/Error'
import SubmitButton from '@/components/form/SubmitButton'
import ModalCloseButton from '@/components/modals/ModalCloseButton'

const { $event } = useNuxtApp()

const localeStore = useLocaleStore()
const requestLoading = ref(false)
const loading = ref(true)
const show = ref(false)
const failed = ref(false)
const translationType =  ref(null)
const translations = ref([])
const originalContent = ref('')
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const translation = ref(null)
// const translationKey = ref(null) never used
const translationModal = ref(null)
const content = ref('')

  /*
  htmlIsValid () {
    if (!this.isHtml) {
      return true
    }
    return stringIsValidHtml(content.value)
  },
    */
const hasHtml = computed(() => {
  return stringContainsHtml(content.value)
})

const showUndo = computed(() => {
  return content.value !== originalContent.value
})

onMounted(() => {
  $event.$on(events.showTranslationModal, toggle)
})

onBeforeUnmount(() => {
  $event.$off(events.showTranslationModal, toggle)
})


const toggle = ({ type, name }) => {
  reset()
  loading.value = true
  requestLoading.value = false
  show.value = !show.value
  translationType.value = type

  const list = Array.isArray(name) ? name : name.split('|')
  translations.value = list.map(item => ({
    translationKey: show.value ? item : '',
    content: ''
  }))

  if (show.value) {
    onShow()
  } else {
    onHide()
  }
}

const htmlIsValid = (content) => {
  if (!isHtml(content)) {
    return true
  }
  return translations.value
    .map(({ content }) => stringIsValidHtml(content))
    .filter(Boolean)
    .length > 0
}

const isHtml = () => {
  return translations.value
    .map(({ content }) => stringContainsHtml(content))
    .filter(Boolean)
    .length > 0
}

const reset = () => {
  translationType.value = null
  failed.value = false

  translations.value = []
}

const onShow = async () => {
  if (![translationTypes.translation, translationTypes.i18n].includes(translationType.value)) {
    failed.value = true
    loading.value = false
    return
  }

  const results = await Promise.all(
    translations.value.map(({ translationKey }) => localeStore.fetchTranslationKey({ key: translationKey, type: translationType.value }))
  )
  translations.value = results.map((result) => {
    if (result) {
      const content = (result?.translationText || '').replaceAll('\r', '\n')
      return {
        ...result,
        content,
        originalContent: content
      }
    } else {
      failed.value = true
      return null
    }
  })

  loading.value = false
}

const onHide = () => {
  show.value = false
  reset()
}

const regret = () => {
  translations.value = translations.value.map(t => ({
    ...t,
    content: t.originalContent
  }))
}

const onSave = async () => {
  if (translations.value.length === 0) {
    return
  }
  requestLoading.value = true

  const payloads = translations.value.map(translation => ({
    ...(translation || {}),
    machineTranslated: false,
    translatedText: translation.content
  }))
  const result = await Promise.all(
    payloads.map(payload => localeStore.updateTranslation({ payload, type: translationType.value }))
  )

  const success = result
    .map(res => res?.status === 'updated' && !!res?.new)
    .filter(Boolean)
    .length > 0

  if (success) {
    onHide()
  } else {
    failed.value = true
  }

  requestLoading.value = false
}
</script>
